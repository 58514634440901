import {
	Grid,
	InputLabel,
	FormControlLabel,
	Switch,
	enerbitColors,
	useSnackbar,
} from "@enerbit/base";
import { useState, useEffect } from "react";
import { EditUserDialog } from "./EditUserDialog";
import { useFormContext, useWatch } from "react-hook-form";
import { useUserStore } from "../../store";
import { useParams } from "react-router-dom";

export const ConfirmSwitch = () => {
	const { id } = useParams();

	const { control, setValue } = useFormContext();
	const isActive = useWatch({ control, name: "is_active" });
	const [openDialog, setOpenDialog] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const { disableUser } = useUserStore((state) => state);

	useEffect(() => {
		if (!isActive) {
			setOpenDialog(false);
		}
	}, [isActive]);

	const handleSwitchClick = () => {
		if (isActive) {
			setOpenDialog(true);
		}
	};

	const handleDialogClose = async (accept: boolean) => {
		if (accept && isActive) {
			try {
				await disableUser(id ?? "");
				setValue("is_active", false);
				enqueueSnackbar("Usuario actualizado con éxito.", {
					variant: "success",
				});
			} catch (error) {
				enqueueSnackbar((error as Error).message, { variant: "error" });
			}
		}
		setOpenDialog(false);
	};

	return (
		<Grid item>
			<InputLabel
				sx={{
					display: "flex",
					alignItems: "end",
					color: enerbitColors.primary.main,
					fontWeight: "bold",
				}}
			>
				¿Desea mantener el usuario activo?
			</InputLabel>
			<FormControlLabel
				control={
					<Switch
						color="secondary"
						checked={isActive}
						onClick={handleSwitchClick}
						disabled={!isActive} // Disable the switch if it's not active
					/>
				}
				label={isActive ? "Si" : "No"}
			/>
			<EditUserDialog
				open={openDialog}
				onClose={() => handleDialogClose(false)}
				onConfirm={() => handleDialogClose(true)}
			/>
		</Grid>
	);
};
