import {
	Grid,
	Box,
	Avatar,
	Typography,
	Tooltip,
	EditOutlinedIcon,
	Chip,
	Stack,
} from "@enerbit/base";
import { StyledIconButton } from "../../common";
import { FC } from "react";
import { IUserItem } from "../../interfaces";
import { Link } from "react-router-dom";
import { StatusUser } from "./StatusUser";
import { stringToColor } from "../../helpers";
import { useFilterUserStore } from "../../store/filter";

export const UserItem: FC<IUserItem> = ({
	pii,
	username,
	id,
	is_active,
	rol,
}) => {
	const { isRoleOpera } = useFilterUserStore();

	const ellipsisStyle = {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	};

	return (
		<Grid item xs={12} lg={4} md={6}>
			<Box
				sx={{
					p: 3,
					border: "1px solid #E4E7EC",
					borderRadius: "12px",
					color: "#667085",
					height: "100%", // Asegura que tome toda la altura disponible
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Stack spacing={3} direction={"row"} alignItems="center">
					<Avatar
						sx={{
							width: 50,
							height: 50,
							backgroundColor: stringToColor(username),
						}}
						src={pii?.form_data?.image_profile?.photography_base64}
					>
						{username.substring(0, 2).toUpperCase()}
					</Avatar>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							flexGrow: 1, // Ajuste para hacer que el Box crezca
							...ellipsisStyle,
						}}
					>
						{pii && (
							<Typography
								variant="h6"
								sx={{ fontWeight: "bold", ...ellipsisStyle }}
							>
								{pii?.full_name.charAt(0).toUpperCase() +
									pii?.full_name.slice(1)}
							</Typography>
						)}
						<Typography variant="h6" sx={{ ...ellipsisStyle }}>
							( {username} )
						</Typography>
					</Box>
					<Tooltip title="Editar">
						<Link
							to={`/users-companies/edit-user/${id}`}
							style={{ textDecoration: "none" }}
						>
							<StyledIconButton color="primary">
								<EditOutlinedIcon />
							</StyledIconButton>
						</Link>
					</Tooltip>
				</Stack>
				<Box
					sx={{
						marginTop: 2,
						display: "flex",
						flexWrap: "wrap", // Añade flex-wrap para permitir múltiples filas
						gap: "8px", // Espacio entre chips
					}}
				>
					<StatusUser status={is_active} type={"user"} />
					{(rol.code === "OPR" || rol.code === "operacion_energia_digital") && (
						<StatusUser status={pii?.status === "enabled"} type={"doc"} />
					)}
					{pii?.state && (
						<Chip
							label={pii?.state}
							sx={{
								background: "#F2F4F7",
								color: "#3D4350",
								fontWeight: "bold",
							}}
						/>
					)}
					{pii?.city && (
						<Chip
							label={pii?.city}
							sx={{
								background: "#F2F4F7",
								color: "#3D4350",
								fontWeight: "bold",
							}}
						/>
					)}
					{pii?.form_data?.measurement_type_trained &&
						pii?.form_data?.measurement_type_trained.length > 0 && (
							<>
								{pii?.form_data?.measurement_type_trained.map((value, key) => {
									return (
										<Chip
											key={key}
											label={value}
											sx={{
												background: "#F2F4F7",
												color: "#3D4350",
												fontWeight: "bold",
											}}
										/>
									);
								})}
							</>
						)}
				</Box>
			</Box>
		</Grid>
	);
};
