import {
	Button,
	DeleteIcon,
	FilterListIcon,
	Menu,
	MenuItem,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { DynamicFilter } from ".";
import type { IItemsJson } from "../../interfaces";
import { getCities, getCompanies, getRolesCode } from "../../services";
import { useUserStore } from "../../store";
import { useFilterUserStore } from "../../store/filter";

export const FiltersUser = () => {
	const { setDepartments, setPage, setSearch, setDebounceSearch } =
		useUserStore();

	const {
		selectedCompanies,
		isRoleOpera,
		selectedCity,
		selectedMeter,
		selectedPiiStatus,
		selectedRoles,
		selectedState,
		selectedStatus,
		selectedCoordinator,
		selectedSafeWorkHeightCoordinator,
		selectedSafeWorkHeight,
		setSelectedCompanies,
		setSelectedCity,
		setSelectedMeter,
		setSelectedPiiStatus,
		setSelectedRoles,
		setSelectedState,
		setSelectedStatus,
		setSelectedCoordinator,
		setSelectedSafeWorkHeightCoordinator,
		setSelectedSafeWorkHeight,
		resetFilters,
	} = useFilterUserStore();

	const [optionsCompanies, setOptionsCompanies] = useState<IItemsJson[]>([]);
	const [optionsRoles, setOptionsRoles] = useState<IItemsJson[]>([]);
	const [optionsStates, setOptionsStates] = useState<IItemsJson[]>([]);
	const [optionsCities, setOptionsCities] = useState<IItemsJson[]>([]);
	const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
	const [isLoadingRoles, setIsLoadingRoles] = useState(false);
	const [isLoadingStates, setIsLoadingStates] = useState(false);
	const [isLoadingCities, setIsLoadingCities] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleRefresh = () => {
		resetFilters();
		setSearch("");
		setDebounceSearch("");
		setPage(0);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const fetchCompanies = async () => {
			setIsLoadingCompanies(true);
			const companies = await getCompanies();
			setIsLoadingCompanies(false);
			setOptionsCompanies(companies);
		};

		fetchCompanies();
	}, []);

	useEffect(() => {
		const fetchRoles = async () => {
			setIsLoadingRoles(true);
			const roles = await getRolesCode();
			setIsLoadingRoles(false);
			setOptionsRoles(roles);
		};

		fetchRoles();
	}, []);

	useEffect(() => {
		const fetchDepartments = async () => {
			setIsLoadingStates(true);
			const departments = await setDepartments();
			setIsLoadingStates(false);
			setOptionsStates(departments);
		};

		fetchDepartments();
	}, [setDepartments]);

	useEffect(() => {
		const fetchCities = async () => {
			if (selectedState.length > 0) {
				setIsLoadingCities(true);
				const cities = await getCities(selectedState[0]);
				setIsLoadingCities(false);
				setOptionsCities(cities);
			}
		};

		fetchCities();
	}, [selectedState]);

	return (
		<div style={{ width: "200px" }}>
			<Button
				size="large"
				fullWidth
				variant="outlined"
				color="primary"
				onClick={handleClick}
				startIcon={<FilterListIcon />}
			>
				Filtros
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				slotProps={{
					paper: {
						style: {
							justifyContent: "start",
							minWidth: "200px",
						},
					},
				}}
			>
				<MenuItem>
					<DynamicFilter
						fullWidth
						options={optionsCompanies}
						selected={selectedCompanies}
						onSelect={setSelectedCompanies}
						label="Empresa"
						disabled={isLoadingCompanies}
					/>
				</MenuItem>
				<MenuItem>
					<DynamicFilter
						fullWidth
						options={optionsRoles}
						selected={selectedRoles}
						onSelect={setSelectedRoles}
						label="Rol"
						disabled={isLoadingRoles}
					/>
				</MenuItem>
				<MenuItem>
					<DynamicFilter
						fullWidth
						options={[
							{ text: "Activo", value: true },
							{ text: "Inactivo", value: false },
						]}
						selected={selectedStatus}
						onSelect={setSelectedStatus}
						label="Estado usuario"
					/>
				</MenuItem>
				{isRoleOpera && (
					<>
						<MenuItem>
							<DynamicFilter
								fullWidth
								options={[
									{ text: "Si", value: true },
									{ text: "No", value: false },
								]}
								selected={selectedCoordinator}
								onSelect={setSelectedCoordinator}
								label="¿Es coordinador/a?"
							/>
						</MenuItem>
						{!selectedCoordinator.includes("true") && (
							<MenuItem>
								<DynamicFilter
									fullWidth
									options={[
										{ text: "Si", value: true },
										{ text: "No", value: false },
									]}
									selected={selectedSafeWorkHeight}
									onSelect={setSelectedSafeWorkHeight}
									label="¿Trabaja en alturas?"
								/>
							</MenuItem>
						)}
						{selectedCoordinator.includes("true") && (
							<MenuItem>
								<DynamicFilter
									fullWidth
									options={[
										{ text: "Si", value: true },
										{ text: "No", value: false },
									]}
									selected={selectedSafeWorkHeightCoordinator}
									onSelect={setSelectedSafeWorkHeightCoordinator}
									label="¿Trabaja en alturas?"
								/>
							</MenuItem>
						)}
						<MenuItem>
							<DynamicFilter
								fullWidth
								options={[
									{ text: "Cumple", value: true },
									{ text: "No cumple", value: false },
								]}
								selected={selectedPiiStatus}
								onSelect={setSelectedPiiStatus}
								label="Estado documentación"
							/>
						</MenuItem>
					</>
				)}
				<MenuItem>
					<DynamicFilter
						fullWidth
						options={optionsStates}
						selected={selectedState}
						onSelect={setSelectedState}
						label="Departamento"
						disabled={isLoadingStates}
					/>
				</MenuItem>
				{selectedState.length > 0 && (
					<MenuItem>
						<DynamicFilter
							fullWidth
							options={optionsCities}
							selected={selectedCity}
							onSelect={setSelectedCity}
							label="Ciudad"
							disabled={isLoadingCities}
						/>
					</MenuItem>
				)}
				{isRoleOpera && (
					<MenuItem>
						<DynamicFilter
							fullWidth
							isMultiple
							options={[
								{
									text: "Directa",
									value: "directa",
								},
								{
									text: "Semidirecta",
									value: "semidirecta",
								},
								{
									text: "Indirecta",
									value: "indirecta",
								},
							]}
							selected={selectedMeter}
							onSelect={setSelectedMeter}
							label="Tipo de medida"
						/>
					</MenuItem>
				)}
				<MenuItem>
					<Button
						fullWidth
						variant="text"
						startIcon={<DeleteIcon />}
						onClick={handleRefresh}
						aria-label="cached"
					>
						Limpiar filtros
					</Button>
				</MenuItem>
			</Menu>
		</div>
	);
};
